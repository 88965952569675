<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-breakpoint="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

    <v-divider class="mb-1" />

    <v-list
      dense
      nav
    >
      <v-list-item>
        <v-list-item-avatar
          class="align-self-center"
          color="white"
        >
          <v-img
            src="../../../public/favicon.png"
            max-height="30"
            contain
          />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            class="display-1"
            v-text="profile.title"
          />
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list
      expand
      nav
    >
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        >
          <!--  -->
        </base-item-group>

        <base-item
          v-else
          :key="`item-${i}`"
          :item="item"
        />
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>

    <!-- ЧТобы показать заказчику меню Оператора и Суперадмина -->
    <template v-slot:append>
      <h1>Временно для роли</h1>
      <!-- <v-btn depressed color="red" @click="changeUser(1)">Admin</v-btn> -->
      <v-btn
        depressed
        color="green"
        @click="changeUser(2)"
        >Operator</v-btn
      >
    </template>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapState } from 'vuex'

export default {
  name: 'DashboardCoreDrawer',

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },
  // rolse [ {id:1, title:superadmin}, {id:2, title:operator},]
  data() {
    return {
      items: [
        {
          icon: 'mdi-view-dashboard',
          title: this.$t('menu.main'),
          to: '/',
          role: [1],
        },
        // {
        //   title: this.$t('menu.clients'),
        //   icon: 'mdi-account-group',
        //   to: '/pages/clients',
        //   role: [1, 2],
        // },
        // {
        //   title: this.$t('menu.goods'),
        //   icon: 'mdi-grill',
        //   to: '/pages/shoq-doner-goods',
        //   role: [1, 2],
        // },
        {
          title: this.$t('menu.orders'),
          icon: 'mdi-library-shelves',
          to: '/pages/orders',
          role: [1, 2],
        },
        {
          title: this.$t('menu.products'),
          icon: 'mdi-food-outline',
          to: '/pages/products',
          role: [1],
        },
        {
          icon: 'mdi-tablet-cellphone',
          title: this.$t('menu.rates'),
          to: '/pages/rates',
          role: [1, 2],
        },
        // {
        //   title: this.$t('menu.suppliers'),
        //   icon: 'mdi-badge-account-horizontal',
        //   to: '/pages/suppliers',
        //   role: [1],
        // },
        {
          title: this.$t('menu.users'),
          icon: 'mdi-account-group',
          to: '/pages/users',
          role: [1, 2],
        },
        // {
        //   title: this.$t('menu.notifications'),
        //   icon: 'mdi-bell',
        //   to: '/pages/notifications',
        //   role: [1],
        // },
        // {
        //   title: this.$t('menu.social-chats'),
        //   icon: 'mdi-message',
        //   to: '/pages/social-chats',
        //   role: [1, 2],
        // },
      ],
    }
  },

  computed: {
    ...mapState(['barColor', 'barImage']),
    drawer: {
      get() {
        return this.$store.state.drawer
      },
      set(val) {
        this.$store.commit('SET_DRAWER', val)
      },
    },
    computedItems() {
      return this.items.map(this.mapItem)
    },
    profile() {
      return {
        avatar: true,
        title: this.$t('title'),
      }
    },
  },

  mounted() {
    // console.log(this.$t)
    // this.changeUser(2);
  },

  methods: {
    changeUser(roleId) {
      // this.items.filter((item) => {
      //   console.log(item.role.includes(2));
      // });
      this.items = this.items.filter((item) => {
        return item?.role.includes(roleId)
      })
    },
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title),
      }
    },
  },
}
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important

  .v-list-item--active
    .v-list-item__icon,
    .v-list-item__content
      color: #fff !important


  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px

      +rtl()
        padding-right: 8px

    .v-list-group__header
      +ltr()
        padding-right: 0

      +rtl()
        padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
          margin-right: 8px

        +rtl()
          margin-left: 8px
</style>
