<template>
  <v-app>
    <app-bar-component />

    <drawer-component />

    <v-main>
      <slot />

      <footer-component />
    </v-main>

    <!-- <settings-component /> -->
  </v-app>
</template>

<script>
import AppBarComponent from '@/components/core/AppBar'
import DrawerComponent from '@/components/core/Drawer'
import SettingsComponent from '@/components/core/Settings'
import FooterComponent from '@/components/core/Footer'

export default {
  name: 'DashboardLayout',

  components: {
    AppBarComponent,
    DrawerComponent,
    SettingsComponent,
    FooterComponent,
  },

  data: () => ({
    expandOnHover: false,
  }),
}
</script>
